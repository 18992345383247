import React, { useEffect, useState } from "react";
import axios from "axios";
import Constants from "../utils/Constants";
import { getAccessToken } from "../utils/Auth";
import {epochSeconds, formatDate, getDetail, isHidden} from "../utils/Functions";
import Notification from "./Notification";
import { Box, Paper, Select, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { IconButton, MenuItem, Stack } from "@mui/material";
import ViewWeekOutlinedIcon from "@mui/icons-material/ViewWeekOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import BackspaceOutlinedIcon from "@mui/icons-material/BackspaceOutlined";
import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import ColumnsDialog from "./ColumnsDialog";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import PoliciesDialog from "./PoliciesDialog";
import DeleteDialog from "./DeleteDialog";
import {toast} from "react-toastify";

let http_str = "https://"
let base_url = http_str.concat(Constants.apiHost, '.', Constants.domainName,'/api/v1/policies')


const useStyles = makeStyles(theme => ({
    outer_div: {
        minHeight: '100vh',
    },
    card: {
        //backgroundColor: '#424242',
        backgroundColor: '#181818',
        color: '#EEEEEE',
    },
    itemBar: {
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: '#222222',
    },
    pageBar: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        backgroundColor: '#222222',
        color: '#AAAAAA',
    },
    tableComponent: {
        borderStyle: 'solid',
        borderColor: '#000',
        borderRadius: '10px',
        borderSpacing: '0px',
        borderRightWidth: '0px',
        borderTopWidth: '1px',
        borderBottomWidth: '0px',
        borderLeftWidth: '0px',
        // backgroundColor: '#222222' // setting this breaks row highlighting
    },
    tableCell: {
        paddingLeft: '12px',
    },
    clickableTableCell: {
        paddingLeft: '12px',
        cursor: 'pointer',
    },
    dialog: {
        backgroundColor: '#333333',
        color: '#CCCCCC',
        margin: '10',
    },
    dialogTitleBar: {
        backgroundColor: '#4e54bf',
        color: '#CCCCCC',
    },
    dialogConfirm: {
        backgroundColor: '#149616',
        color: '#CCCCCC',
    },
    dialogNeutral: {
        backgroundColor: '#4e54bf',
        color: '#CCCCCC',
    },
    dialogWarning: {
        backgroundColor: '#d42842',
        color: '#CCCCCC',
    },
    primaryColor: {
        color: '#000',
        backgroundColor: '#111'
    },
    whiteColor: {
        color: "#EEE",
    },
    hide: {
        display: 'none',
    },
    spaced: {
        margin: '10px',
    }
}))

let headers = [
    { field: 'pk', headerName: 'ID', width: 350, hidden: true },
    { field: 'policy_name', headerName: 'Name', width: 300, hidden: false },
    { field: 'ref_count', headerName: 'Used', width: 50, hidden: false },
    { field: 'action_suspend_user', headerName: 'Suspend User', width: 50, hidden: false },
    { field: 'action_restore_file', headerName: 'Restore File', width: 50, hidden: false },
    { field: 'created', headerName: 'Created', width: 200, hidden: false},
    { field: 'modified', headerName: 'Modified', width: 200, hidden: false},
    { field: 'edit', headerName: 'Edit', width: 50, hidden: false },
    { field: 'delete', headerName: 'Delete', width: 50, hidden: false },
  ]

const toastOptions = {
    position: "bottom-right",
    autoClose: false,
    hideProgressBar: false,
    pauseOnHover: true,
    draggable: true
}

export default function Policies() {
    const classes= useStyles()
    const [items, setItems] = useState([])
    const [selectedItem, setSelectedItem] = useState(null)
    const [selectedRow, setSelectedRow] = useState(null)
    const [columnsMode, setColumnsMode] = useState(false)
    const [addMode, setAddMode] = useState(false)
    const [editMode, setEditMode] = useState(false)
    const [deleteMode, setDeleteMode] = useState(false)
    const [limit, setLimit] = React.useState(10)
    const [pages, setPages] = useState([0])
    const [pageIndex, setPageIndex] = useState(0)
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    const getItems = async () => {
        let url = base_url
        if (pageIndex !== 0) {
            console.log('fetch page: ' + pages[pageIndex])
            url = url + '?limit=' + limit + '&nextPageKey=' + pages[pageIndex]
        }
        else {
            console.log('fetch page: 0')
            url = url + '?limit=' + limit
        }
        const http_headers = { "Content-Type": "application/json","Authorization": await getAccessToken() }
        console.log('url: ' + url)
        await axios
            .get(url, { headers: http_headers })
            .then(res => {
                if (pageIndex === pages.length - 1) {
                    let npk = res.data['nextPageKey']
                    if (npk) {
                        let tmp_arr = pages
                        tmp_arr.push(npk)
                        setPages(tmp_arr)
                    }
                }
                const items = res.data['policies'];
                setItems(items)
            })
            .catch(err => {
                if (err.response) {
                  console.log('bad response from server: ' + err.response)
                } else if (err.request) {
                  console.log('no response from server: ' + err.request)
                } else {
                  console.log(err)
                }
                Notification(`Error while fetching ${url}. ${err}`, 'error');
            })
    }


    const addItem = async (fields) => {
        let url = base_url
        const http_headers = { 'Content-Type': 'application/json','Authorization': await getAccessToken() }
        let params = {
            'policy_name': fields.policy_name,
            'ransomware': fields.ransomware,
            'ransomnote': fields.ransomnote,
            'magic_bytes': fields.magic_bytes,
            'safe': fields.safe,
            'blocked': fields.blocked,
            'enable_ransomnote_detection': fields.enable_ransomnote_detection,
            'action_suspend_user': fields.action_suspend_user,
            'action_suspend_access_key': fields.action_suspend_access_key,
            'action_block_ip': fields.action_block_ip,
            'action_kill_process': fields.action_kill_process,
            'action_delete_version': fields.action_delete_version,
        }
        const toastId = toast.loading('adding Policy...', toastOptions)
        await axios
            .post(url, { 'policy': params },{ headers: http_headers })
            .then(res => {
                console.log(res.data);
                // Add the data submitted back to the local table
                params.pk = res.data['pk']
                const epoch = epochSeconds()
                params.created = epoch
                params.modified = epoch
                setItems(items.concat([params]))
                toast.update(toastId, {render: `${res.data['msg']}`, type: "success", isLoading: false, autoClose: 2000})
            })
            .catch(err => {
                if (err.response) {
                  console.log('bad response from server: ' + err.response)
                } else if (err.request) {
                  console.log('no response from server: ' + err)
                } else {
                  console.log(err)
                }
                toast.update(toastId, {render: `Error adding Policy: ${err}`, type: "error", isLoading: false})
            })
    }

    const editItem = async (fields) => {
        let url = base_url + "/" + fields.pk
        const http_headers = { 'Content-Type': 'application/json','Authorization': await getAccessToken() }
        let params = {
            'pk': fields.pk,
            'policy_name': fields.policy_name,
            'ransomware': fields.ransomware,
            'ransomnote': fields.ransomnote,
            'magic_bytes': fields.magic_bytes,
            'safe': fields.safe,
            'blocked': fields.blocked,
            'enable_ransomnote_detection': fields.enable_ransomnote_detection,
            'action_suspend_user': fields.action_suspend_user,
            'action_suspend_access_key': fields.action_suspend_access_key,
            'action_block_ip': fields.action_block_ip,
            'action_kill_process': fields.action_kill_process,
            'action_delete_version': fields.action_delete_version,
        }
        const toastId = toast.loading('saving Policy...', toastOptions)
        await axios
            .put(url, { 'policy': params },{ headers: http_headers })
            .then(res => {
                console.log(res.data);
                // Add the data submitted back to the local table
                // setItems(items.concat([params]))
                toast.update(toastId, {render: `${res.data['msg']}`, type: "success", isLoading: false, autoClose: 2000})
                forceUpdate()
            })
            .catch(err => {
                if (err.response) {
                  console.log('bad response from server: ' + err.response)
                } else if (err.request) {
                  console.log('no response from server: ' + err)
                } else {
                  console.log(err)
                }
                toast.update(toastId, {render: `Error saving Policy ${fields.pk}: ${err}`, type: "error", isLoading: false})
                // Notification(`Error editing policy ${fields.pk}. ${err}`, 'error');
            })
    }

    const deleteItem = async (uuid) => {
        let url = base_url + "/" + uuid
        const headers = { 'Content-Type': 'application/json','Authorization': await getAccessToken() }
        const toastId = toast.loading('deleting Policy...', toastOptions)
        await axios
            .delete(url, { headers: headers })
            .then(res => {
                console.log(res.data);
                let items_filtered = items.map((x) => x)
                items_filtered = items_filtered.filter(i => i.pk !== uuid)
                setItems(items_filtered)
                toast.update(toastId, {render: `${res.data['msg']}`, type: "success", isLoading: false, autoClose: 2000})
            })
            .catch(err => {
                if (err.response) {
                  console.log('bad response from server: ' + err.response)
                } else if (err.request) {
                  console.log('no response from server: ' + err)
                } else {
                  console.log(err)
                }
                toast.update(toastId, {render: `Error deleting Policy: ${err}`, type: "error", isLoading: false})
            })
    }

    // PrefetchSelectedRow will fetch list details every row you click
    // It should make loading the edit screen faster, but it might add a lot more API calls
    const prefetchSelectedRow = (uuid) => {
        if (selectedRow === uuid) {
            // deselect a row if it's already selected
            setSelectedRow(null)
        }
        else {
            setSelectedRow(uuid)
            setSelectedItem(getItemByPk(uuid))
            //getDetail(uuid)
        }
    }

    function getItemByPk(pk) {
        return items.find(i => i.pk === pk)
    }

    const handleOpenColumns = () => {
        setColumnsMode(true);
    };

    const handleCloseColumns = () => {
        setColumnsMode(false);
    };

    const handleOpenAdd = () => {
        setAddMode(true);
        console.log("add new site")
    };

    const handleAdd = (fields) => {
        let addData = addItem(fields)
        // check addData for error and notify user
        setAddMode(false)
    }

    const handleCloseAdd = () => {
        setAddMode(false);
    };

    const handleOpenEdit = (e, uuid) => {
        setSelectedRow(uuid)
        console.log("edit " + selectedRow)
        getDetail(base_url, 'policy', uuid, setSelectedItem, setEditMode)
    };

    const handleEdit = (item) => {
        let editData = editItem(item)
        // check editData for error and notify user
        setEditMode(false)
    }

    const handleCloseEdit = () => {
        setEditMode(false);
    };

    const handleOpenDelete = (e, uuid) => {
        setSelectedRow(uuid)
        setDeleteMode(true);
        console.log("delete " + selectedRow)
    };

    const handleDelete = (uuid) => {
        deleteItem(uuid)
        setDeleteMode(false);
    };

    const handleCloseDelete = () => {
        setDeleteMode(false);
    };

    const handleLimit = (event) => {
        setLimit(event.target.value);
    };

    const handlePreviousPage = () => {
        if (pageIndex > 0) {
            setPageIndex(pageIndex - 1)
        }
    }

    const handleNextPage = () => {
        if (pageIndex < pages.length - 1) {
            setPageIndex(pageIndex + 1)
        }
    }

    const toggleColumn = (event) => {
        for (let i = 0; i < headers.length; i++) {
            if (headers[i]['field'] === event.target.id) {
                headers[i]['hidden'] = !headers[i]['hidden']
            }
        }
        //  ugly but since the checkboxes aren't tied to state, they are tied to column definitions, they don't update
        forceUpdate()
    };

    useEffect(() => {
        getItems()
    }, [limit, pageIndex] )

    return (
        <div className={classes.outer_div} style={{padding: '20px'}}>
            <Paper className={classes.card} variant="outlined" style={{padding: '15px'}}>
                <Stack direction={"row"} justifyContent={"space-between"}>
                    <Typography variant="h4" >Policies</Typography>
                    <Box>
                        <ViewWeekOutlinedIcon className={classes.spaced} color={"primary"}
                                              onClick={() => handleOpenColumns()}/>
                        <AddOutlinedIcon className={classes.spaced} color={"primary"}
                                         onClick={() => handleOpenAdd()}/>
                    </Box>
                </Stack>
                {/* TABLE */}
                <table className={classes.tableComponent} style={{width: 100 + "%"}} >
                    <thead>
                        <tr className={classes.tableComponent} >
                            {headers.map(header => (
                                <th width={header.width} className={ header.hidden === true ? classes.hide: '' }>{header.headerName}</th>
                                ))}
                        </tr>
                    </thead>
                    <tbody  className={classes.tableComponent} >
                        {items.map(val =>
                            <tr key={val.pk} onClick={() => prefetchSelectedRow(val.pk)}
                                bgcolor={selectedRow === val.pk ? '#242c42' : '#222222'} className={classes.tableComponent}>
                                <td className={isHidden('pk', headers) ?  classes.hide : classes.tableCell}>{val.pk}</td>
                                <td className={isHidden('policy_name', headers) ? classes.hide : classes.tableCell}>{val.policy_name}</td>
                                <td className={isHidden('ref_count', headers) ? classes.hide : classes.tableCell}>{val.ref_count}</td>
                                <td className={isHidden('action_suspend_user', headers) ? classes.hide : classes.tableCell}>{val.action_suspend_user ? 'yes' : 'no'}</td>
                                <td className={isHidden('action_delete_version', headers) ? classes.hide : classes.tableCell}>{val.action_delete_user ? 'yes' : 'no'}</td>
                                <td className={isHidden('created', headers) ? classes.hide : classes.tableCell}>{formatDate(val.created)}</td>
                                <td className={isHidden('modified', headers) ? classes.hide : classes.tableCell}>{formatDate(val.modified)}</td>
                                <td className={isHidden('edit', headers) ? classes.hide : classes.clickableTableCell}>
                                    <EditOutlinedIcon color={"primary"} onClick={(e) => handleOpenEdit(e, val.pk)}/></td>
                                <td className={isHidden('delete', headers) ? classes.hide : classes.clickableTableCell}>
                                    <BackspaceOutlinedIcon color={"error"} onClick={(e) => handleOpenDelete(e, val.pk)}/></td>
                            </tr>
                        )}
                    </tbody>
                </table>
                <Paper className={classes.itemBar} elevation={1}>
                    <Stack className={classes.pageBar}
                   elevation={0}
                    direction="row"
                    alignItems="center"
                    sx={{ width: 1, height: "5vh" }} >
                        <IconButton onClick={() => getItems()}><RefreshOutlinedIcon className={classes.spaced} /></IconButton>
                        <Typography className={classes.spaced}>Rows per page:</Typography>
                        <Select
                            classes={{ root: classes.whiteColor, icon: classes.whiteColor }}
                            labelId="fetch-limit-select"
                            id="fetch-limit-select"
                            value={limit}
                            label="Limit"
                            onChange={handleLimit}
                        >
                          <MenuItem value={10}>10</MenuItem>
                          <MenuItem value={25}>25</MenuItem>
                          <MenuItem value={50}>50</MenuItem>
                        </Select>
                        <IconButton disabled={pageIndex === 0} onClick={() => handlePreviousPage()}><ChevronLeftOutlinedIcon /></IconButton>
                        <Typography variant={'h6'}>{pageIndex + 1}</Typography>
                        <IconButton disabled={pageIndex >= pages.length - 1} onClick={() => handleNextPage()}><ChevronRightOutlinedIcon /></IconButton>
                    </Stack>
                </Paper>

                {/* DIALOGS */}
                {/* COLUMNS DIALOG */}
                <ColumnsDialog
                    variant={"outlined"}
                    open={columnsMode}
                    onClose={handleCloseColumns}
                    onChange={toggleColumn}
                    headers={headers}
                />

                {/* ADD DIALOG */}
                <PoliciesDialog
                    mode={'add'}
                    open={addMode}
                    onClose={handleCloseAdd}
                    onSave={handleAdd}
                    item={null}
                    data={[]}
                />

                {/* EDIT DIALOG */}
                <PoliciesDialog
                    mode={'edit'}
                    open={editMode}
                    onClose={handleCloseEdit}
                    onSave={handleEdit}
                    item={selectedItem}
                />

                {/* DELETE DIALOG */}
                <DeleteDialog
                    open={deleteMode}
                    onClose={handleCloseDelete}
                    onDelete={handleDelete}
                    type={'policy'}
                    row={selectedRow}
                    item={selectedItem}
                />
            </Paper>
        </div>
    )
}