const Constants = {

  applicationName: 'RansomStop',

  customerName: 'mdev',
  apiHost: 'mdev-api',
  domainName: 'ransomstop.net',
  version: '24.8.26',
  userPoolId: 'us-east-1_fNdOJnot6',
  userPoolClientId: '4184oc90qf4e47oeconmo3in1e',
  authDomain: 'mdev-ransomstop-net.auth.us-east-1.amazoncognito.com',
  region: 'us-east-1',
  invokeUrl: '',

}
export default Constants